import { SVR3DFL, WEB_CLR } from "./enums/livenessTestType";
import {
  FACE_CAPTURE,
  // eslint-disable-next-line
  FACE_CAPTURE_INSTRUCTIONS,
  // eslint-disable-next-line
  FACE_CAPTURE_CONFIRM,
  // eslint-disable-next-line
  DOCUMENT_CAPTURE_INSTRUCTIONS,
  DOCUMENT_CAPTURE_PORTRAIT,
  DOCUMENT_CAPTURE_CONFIRM,
  DOCUMENT_CONFIRM_OCR,
  THANK_YOU,
  VERIFY_IDENTITY,
  LIVENESS_TEST,
  INSTRUCTIONS_CLR,
  INSTRUCTIONS_FACE_LIVENESS_3D,
  FACE_LIVENESS_3D,
  OLD_FACE_LIVENESS_3D,
  OLD_INSTRUCTIONS_FACE_LIVENESS_3D,
  INSTRUCTION_VIDEO_FACE_LIVENESS_3D,
  // eslint-disable-next-line
  NEW_DOCUMENT_CAPTURE_INSTRUCTIONS,
  INTRO_CONFIRM_TO_START,
  // eslint-disable-next-line
  GUIDANCE,
  // eslint-disable-next-line
  DOCUMENT_CAPTURE_OTHER_SELECTOR,
  DOCUMENT_CAPTURE_TYPE2_SELECT,
  // CHECK_DOPA
} from "./enums/pages";

export const steps = [
  //0 Welcome
  [INTRO_CONFIRM_TO_START],

  //1 Create application
  // [VALIDATE_PHONE, VERIFICATION_CODE],

  //2 Document capture
  [
    VERIFY_IDENTITY,
    // DOCUMENT_CAPTURE_INSTRUCTIONS,
    // NEW_DOCUMENT_CAPTURE_INSTRUCTIONS,
    DOCUMENT_CAPTURE_PORTRAIT,
    DOCUMENT_CAPTURE_CONFIRM,
  ],

  //3 Document OCR Confirm
  [DOCUMENT_CONFIRM_OCR],

  //4 Seconds Document
  [],

  //5 Face capture
  [
    // FACE_CAPTURE_INSTRUCTIONS,
    FACE_CAPTURE,
    // FACE_CAPTURE_CONFIRM
  ],

  //6 Liveness
  [OLD_INSTRUCTIONS_FACE_LIVENESS_3D, OLD_FACE_LIVENESS_3D],

  //7 Submit
  [THANK_YOU],
];

export function populateLiveness(livenessEnum, new3dfl, selfie3dfl) {
  // console.log(livenessEnum, new3dfl, selfie3dfl)
  if (livenessEnum === WEB_CLR) {
    addLivenessPages([INSTRUCTIONS_CLR, LIVENESS_TEST]);
  }
  if (livenessEnum === SVR3DFL) {
    if (new3dfl) {
      if (selfie3dfl) {
        addLivenessPages([
          INSTRUCTIONS_FACE_LIVENESS_3D,
          INSTRUCTION_VIDEO_FACE_LIVENESS_3D,
          FACE_LIVENESS_3D,
        ]);
        const selfieIndex = steps.findIndex((x) => x.indexOf(FACE_CAPTURE) > -1);
        if (selfieIndex > -1) {
          steps.splice(selfieIndex, 1);
        }
      } else {
        addLivenessPages([
          INSTRUCTIONS_FACE_LIVENESS_3D,
          INSTRUCTION_VIDEO_FACE_LIVENESS_3D,
          FACE_LIVENESS_3D,
        ]);
      }
    } else {
      addLivenessPages([
        OLD_INSTRUCTIONS_FACE_LIVENESS_3D,
        INSTRUCTION_VIDEO_FACE_LIVENESS_3D,
        OLD_FACE_LIVENESS_3D,
      ]);
    }
  }
  const emptyStepIndex = steps.findIndex((x) => x.length < 1);
  if (emptyStepIndex > -1) {
    steps.splice(emptyStepIndex, 1);
  }
}

export function populateDocument(type) {
  // console.log(type)
  if (type !== "ID_CARD") {
    const emptyStepIndex = steps.findIndex((x) => x[0] === DOCUMENT_CAPTURE_TYPE2_SELECT);
    if (emptyStepIndex > -1) {
      steps.splice(emptyStepIndex, 1);
    }
    addLivenessPages([
      DOCUMENT_CAPTURE_TYPE2_SELECT
    ])
  } else {
    const emptyStepIndex = steps.findIndex((x) => x[0] === DOCUMENT_CAPTURE_TYPE2_SELECT);
    if (emptyStepIndex > -1) {
      steps.splice(emptyStepIndex, 1);
    }
  }

  const emptyStepIndex = steps.findIndex((x) => x.length < 1);
  if (emptyStepIndex > -1) {
    steps.splice(emptyStepIndex, 1);
  }
}

function addLivenessPages(livenessPages) {
  const livenessStepIndex = steps.findIndex((x) => x.length < 1);
  // console.log(livenessStepIndex)
  if (livenessStepIndex > -1) {
    steps[livenessStepIndex].splice(0, 0, ...livenessPages);
  } else {
    steps.splice(3, 0, ...[livenessPages]);
    // console.log(steps)
  }
  // console.log(steps)
}

//TODO@gva refactor step logic so it complies to nonlinear page movement
export function getStepAndIndex(currentPage, step) {
  if (!steps[step]) {
    step = getPageStep(currentPage);
    if (step < 0)
      return;
    // throw Error("Cannot find page " + currentPage + " in form steps");
  }
  let index = steps[step].indexOf(currentPage);
  if (index === -1)
    return;
  // throw Error("Cannot find page in steps array");
  return { step, index };
}

export function previous(currentPage, currentStep) {
  const { step, index } = getStepAndIndex(currentPage, currentStep);
  return steps[step][index - 1] || (step && steps[step - 1][0]) || INTRO_CONFIRM_TO_START;
}

export function nextPage(currentPage, currentStep) {
  const { step, index } = getStepAndIndex(currentPage, currentStep);
  return (
    steps[step][index + 1] ||
    (steps.length > step && steps[step + 1][0]) ||
    INTRO_CONFIRM_TO_START
  );
}

/** Navigates to next step, no matter index of current page */
// TODO@Ljubo: Check if this method is redundant
export function nextStep(currentPage) {
  const step = getPageStep(currentPage);
  return (steps.length > step && steps[step + 1][0]) || INTRO_CONFIRM_TO_START;
}

export function getPageStep(page) {
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].includes(page)) {
      return i;
    }
  }
  return -1;
}
