import { combineReducers } from "redux";

import { application, setApplicationParams } from "./application";
import { configuration } from "./configuration";
import { documentCapture, documentCaptureSetData } from "./documentCapture";
import { documentCaptureConfiguration } from "./documentCaptureConfiguration";
import { faceCapture } from "./faceCapture";
import { faceLiveness3D } from "./faceLiveness3D";
import { livenessTest } from "./livenessTest";
import { navigation } from "./navigation";
import { resendVerificationCode } from "./resendVerificatioCode";
import { apiFetch } from "./apiFetch";
import { camera } from "./camera";
import { changeMultiLanguage } from "./changeLanguage";
import { documentCaptureSecondType } from "./documentCaptureSecondType";
import { dopaCheck } from "./dopaCheck";
import { expireTimeOut } from "./expireTime";

export const rootReducer = combineReducers({
  application,
  configuration,
  documentCapture,
  documentCaptureConfiguration,
  faceCapture,
  faceLiveness3D,
  livenessTest,
  navigation,
  resendVerificationCode,
  apiFetch,
  camera,
  changeMultiLanguage,
  documentCaptureSecondType,
  setApplicationParams,
  documentCaptureSetData,
  dopaCheck,
  expireTimeOut
});
