/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import "./style.css";
import ApplicationProgressBar from "./ApplicationProgressBar";
import ProcessingOverlay from "./ProcessingOverlay";
import * as S from "./PageContent.styles";

export const PAGE_CONTENT_TYPE_CAPTURE = "capture";
export const PAGE_CONTENT_TYPE_INSTRUCTIONS = "instructions";

function PageContent({ toggleLoading, children, type, progressBar, isSpinner }) {
  return (
    <ProcessingOverlay toggleLoading={toggleLoading} overLay={type === PAGE_CONTENT_TYPE_CAPTURE ? true : false} isSpinner={isSpinner}>
      {progressBar && <ApplicationProgressBar />}
      {type === PAGE_CONTENT_TYPE_CAPTURE ? (
        <div className="page-capture">{children}</div>
      ) : (
        <S.PageContent>
          <div className="m-20">
            {process.env.REACT_APP_SHOW_BUILD_VERISON === "true" && <a className="justify-content-end" style={{ alignContent: "end" }}>build 202400004</a>}
            {/* <div className="card">{children}</div> */}
            {children}
          </div>
        </S.PageContent>
      )}
    </ProcessingOverlay>
  );
}

export default PageContent;
