/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { go, nextStep } from "logic/actions/navigation";
import { documentsGet, documentUpdate } from "logic/actions/api/document";
import { retry, newDocumentSelected, resetingDocumentQualityRetries, retryIncorrectIdCard } from 'logic/actions/documentCapture';
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import { func, string } from "prop-types";
import { applicationSubmitForDopa, applicationSubmitTwoElementForDopa, applicationCheckDopaResult } from 'logic/actions/api/dopaCheck';
import { setDataDOPACheck, setTwoElementCheck } from 'logic/actions/dopaCheck';
import {
    logEvent,
    step_completed,
} from "logic/eventLogger";
import {
    DOCUMENT_CAPTURE_INSTRUCTIONS,
    VERIFY_IDENTITY,
    DOCUMENT_CAPTURE_PORTRAIT,
    DOCUMENT_CAPTURE_OTHER_SELECTOR
} from "logic/enums/pages";
import PageContent from 'Components/PageContent';
import "../../style.css";

import { countDopaRetries, resetDopaCheck, setResponseDopa } from "logic/actions/dopaCheck";
import { dopaRetry } from "logic/actions/documentCapture";
import imgerror from '../../../../assets/error-icon.png';
import imgwarning from '../../../../assets/warning-icon.png';
import idcardBack from '../../../../assets/idcard-back.png';
import otherSelectTH from '../../../../assets/picture_on_alert[TH].png';
import otherSelectEN from '../../../../assets/picture_on_alert[EN].png';

export class PageShowOCRIdentityCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentId: null,
            buttonsDisabled: false,
            error: "",
            message: null,
            documentOCRDataLoaded: false,
            documentOCRData: null,
            onModal: false,
            onModalExample: false,
            onModalBirthDayExample: false,
            onModalReviewInfomation: false,
            onModalIncorrectIDCard: false,
            visualZoneObjectData: null,
            tempBirthDay: "",
            field: {},
            errors: {},
            idCardValueFromOCR: '',
            incorrectIDCard: false,
            titleNameTh: "",
            titleNameEn: "",
            gender: "",
            dateOfBirthValue: null,
            formIsValid: true,
            inputGender: '',
            firstNameValid: true,
            lastNameValid: true,
            nameEngValid: true,
            middleNameValid: true,
            pidValid: true,
            laserValid: true,
            birtDayValid: true,
            genderValid: true,
            statusBirthDay: 0,
            dopaStatus: true,
            checkCount: true,
            dopaServer: false,
            textStatusTitle: "",
            textStatusText: "",
            countRetry: 0,
            btnSubmitsts: true,
            disableField: false,
            disableIdField: false,
            disableRequiredField: false,
            dopaResultStatus: false,
            countGetApi: 0,
            modalReprocess: false,
            modalServerError: false,
            statusReprocess: false,
            disableBtnReprocess: true,
            placeHolder: "",
            birthDayFormat: "##/##/####",
            setCurrentDate: "",
            defaultCountRetry: 2,
            riskStatus: false,
            isign: false,
            modalErrType: false,
            dopaAutoRetryStatus: false,
            dopaAutoRetryErrorMessage: false,
        };
        this.countReprocess = 5;
        this.submit.bind(this);
        this.getDocumentOCRData.bind(this);
        this.documentPut.bind(this);
        this.confirmData.bind(this);
        this.handleChange.bind(this);
        this.selectGender.bind(this);
        this.dopaCheckSubmit.bind(this);
        this.goRecheckOCR.bind(this);
        this.cancelDopa.bind(this);
        this.pageScrollDown.bind(this);

        if (!getTimePassed("document_ocr_confirm")) startEventTimer("document_ocr_confirm");
    }

    static propTypes = {
        retry: func,
        base64CroppedFirstPage: string,
        base64CroppedSecondPage: string,
        documentType: string,
        documentsPut: func,
    };
    UNSAFE_componentWillMount() {
      if (this.props.pathname === 'iframe' && this.props.secondDevice !== "yes") { 
        this.setState({ isign: true })
      }
    }
    componentDidMount() {
        this.placeholderOfDate();
        this.setState({ titleNameTh: this.props.t("PageDocumentConfirmOCRData.id_card.title_name") })
        if (this.props.customerData === null) {
            // this.getDocumentOCRData()
          if (this.state.isign && this.hasRequiredFieldParams()) {
            this.getDocumentOCRData()
              .finally(() => {
                this.setState({ disableRequiredField: true })
                this.disableRequiredField()
                this.enableStepValidateIdCard()
            })
          } else {
            this.getDocumentOCRData()
          }
        }
        if (this.props.customerData !== null) {
            this.reloadOCRData()
        }
        if (this.props.risk) {
          this.setState({ riskStatus: true })
          if (this.props.risk.includes('low')) {
            this.setState({ defaultCountRetry: 3 })
          }
        }
    }

    placeholderOfDate = () => {
        let date = new Date().toLocaleDateString('th-TH',
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            })
        let splitDate = date.split('/')
        let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
        let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
        let output = day + '/' + mounth + '/' + splitDate[splitDate.length - 1]
        this.setState({
            placeHolder: 'ex. ' + output,
            setCurrentDate: output
        })
    }

    pageScrollDown() {
        // console.log("scroll down")
        window.scrollBy(0, document.body.scrollHeight);
        // setTimeout(this.pageScroll(),10);
    }

    reloadOCRData = () => {
        let customerData = this.props.customerData
        this.firstName.value = customerData.confirmedGivenNames
        this.lastName.value = customerData.confirmedLastname
        this.middleName.value = customerData.middleName
        this.idCardNum.value = customerData.confirmedLaser
        this.idCard.value = customerData.confirmedIdNumber
        this.birthDay.value = customerData.birthOfDate
        this.setState({
            documentOCRDataLoaded: true,
            titleNameTh: customerData.titleNameTh,
            titleNameEn: customerData.titleNameEn
        });
        this.selectGender(customerData.titleNameEn)
    }
    enableStepValidateIdCard = () => {
      if(process.env.REACT_APP_ENABLE_STEP_VALIDATE_ID_CARD === 'false') return
      this.goCompareIDCard()
    }
    goCompareIDCard = () => {
      const { idCard } = this.props.requiredField
      const { idCardValueFromOCR } = this.state
      // console.log(idCard, idCardValueFromOCR);
      if (this.props.countIncorrectIDCard <= 0) { return this.setState({ modalErrType: true })}
      if (idCard.length !== idCardValueFromOCR.length) { 
        this.props.retryIncorrectIdCard()
        this.setState({ onModalIncorrectIDCard: true }) 
      }
      let countErrorCharacter = 0
      Array.from(idCard).map((v, i) => {
        if (idCard[i] !== idCardValueFromOCR[i]) { return countErrorCharacter++ }
        return countErrorCharacter
      })
      if (countErrorCharacter > 2) { 
        this.props.retryIncorrectIdCard()
        this.setState({ 
          onModalIncorrectIDCard: true, 
          incorrectIDCard: true 
        })
      }
    }
    autoRecheckDopa = (birthday) => {
        let splitDate = birthday.value.split('/')
        let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
        let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
        return `${day}${mounth}` === '0101'
    }
    dopaContinueRecheck = () => {
        if (this.dopaTimeInterval) {
            clearTimeout(this.dopaTimeInterval)
            this.pageScrollDown();
            return this.dopaTimeInterval = ''
        }
        this.dopaTimeInterval = setTimeout(() => {
            this.documentPut()
        }, 0);
    }
    showMessage = (message) => {
        return this.state.dopaAutoRetryStatus ? message : ''
    }
    goDocumentPut = () => {
      this.setState({ onModalReviewInfomation: false })
      this.documentPut()
    }
    documentPut = async () => {
        startEventTimer("id_card_ocr_conirm_[put-data]")
        logEvent("id_card_ocr_conirm_[put-data]", {
            start_time: window.performance.now(),
            response: null
        })
        this.setState({
            btnSubmitsts: false,
            documentOCRDataLoaded: false,
            onModal: false,
            disableField: true,
            disableIdField: true
        });
        let setDate = null;
        let splitDate, ogBirthday;
        splitDate = this.birthDay.value.split('/')
        if (splitDate[0] === '-' && splitDate[1] === '-') {
            setDate = splitDate[2]
            let year = parseInt(splitDate[2]) - 543;
            ogBirthday = year.toString() + '0101';
        } else if (splitDate[0] === '-') {
            setDate = splitDate[2] + splitDate[1]
            let year = parseInt(splitDate[2]) - 543;
            ogBirthday = year.toString() + splitDate[1] + '01';
        } else {
            let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
            let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
            setDate = splitDate[2] + mounth + day
            if(`${day}${mounth}` === '0101') {
              setDate = this.state.dopaAutoRetryStatus ? `${splitDate[2]}0000` : `${splitDate[2]}${mounth}${day}`;
            }
            let year = parseInt(splitDate[2]) - 543;
            ogBirthday = year.toString() + mounth + day
        }
        const uncroppedFirstPage = this.props.base64CroppedFirstPage;
        const uncroppedSecondPage = this.props.base64CroppedSecondPage;
        let updatedOCRFields = {
            confirmedGivenNames: this.firstName.value.trim(),
            confirmedLastname: this.lastName.value.trim(),
            middleName: this.middleName.value.trim(),
            confirmedLaser: this.idCardNum.value.trim().toUpperCase(),
            confirmedIdNumber: this.idCard.value.trim(),
            confirmedBirthdate: setDate,
            gender: this.state.gender,
            birthOfDate: ogBirthday
        }
        this.props.setDataDOPACheck(updatedOCRFields);
        this.props.documentUpdate(
            uncroppedFirstPage,
            uncroppedSecondPage,
            this.props.documentType,
            updatedOCRFields
        )
            .then((res) => {
                logEvent("id_card_ocr_conirm_[put-data]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[put-data]"),
                    response: res
                })
                // this.submit();
                if (this.state.dopaResultStatus) {
                    this.handleDopaSuccess()
                } else {
                    // this.dopaCheckSubmit();
                    if (this.state.riskStatus && this.props.dopacheckRetries > 2) {
                      this.dopaCheckTwoElementSubmit()
                      this.props.setTwoElementCheck()
                    } else {
                      this.dopaCheckSubmit()
                    }
                }
            })
            .catch((err) => {
                logEvent("id_card_ocr_conirm_[put-data]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[put-data]"),
                    response: err
                })
                if (err.response && err.response.data && err.response.data.message) {
                    this.setState({
                        btnSubmitsts: false,
                        documentOCRDataLoaded: true,
                        error: err.response.data.message,
                        disableField: false,
                        countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                        dopaStatus: false,
                        dopaServer: true,
                        checkCount: true,
                        textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                        textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
                    });
                } else {
                    this.setState({
                        btnSubmitsts: false,
                        error: err.toString(),
                        documentOCRDataLoaded: true,
                        disableField: false,
                        countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                        dopaStatus: false,
                        dopaServer: true,
                        checkCount: true,
                        textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                        textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
                    });
                }
                this.pageScrollDown();
            });
    }
    countDownReprocess = () => {
        let countdown = setInterval(() => {
            this.countReprocess = this.countReprocess - 1
            if (this.countReprocess <= 0) {
                clearInterval(countdown)
                this.setState({ disableBtnReprocess: false })
            } else {
                this.setState({ disableBtnReprocess: true })
            }
        }, 1000)
    }
    getDocumentOCRData = async () => {
        startEventTimer("id_card_ocr_conirm_[get-data]")
        logEvent("id_card_ocr_conirm_[get-data]", {
            start_time: window.performance.now(),
            response: null
        })
        this.setState({
            documentOCRDataLoaded: false,
            modalReprocess: false
        });
        await this.props.documentsGet(this.state.documentId)
            .then((response) => {
                // let visualZoneObject = this.state.documentOCRData.visualZone
                // let visualZoneObject = response.data.serverProcessed.ocrData.sensitiveData.visualZone
                logEvent("id_card_ocr_conirm_[get-data]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[get-data]"),
                    response: response
                })
                let visualZoneObject = response.data.visualZone
                // this.setState({ visualZoneObjectData: visualZoneObject })
                let keys = Object.keys(visualZoneObject);
                let valueArray = [];
                keys.forEach(function (key) {
                    valueArray.push(visualZoneObject[key]);
                });
                // console.log(valueArray)
                valueArray.forEach((val) => {
                    // console.log(val)
                    if (val.name === 'Surname And Given Names (1054)') {
                        this.fullnameTh = val.value.split(' ')
                        this.firstName.value = this.fullnameTh[1] ? this.fullnameTh[1] : ''
                        this.lastName.value = this.fullnameTh[this.fullnameTh.length - 1] ? this.fullnameTh[this.fullnameTh.length - 1] : ''
                    } else if (val.name === 'Document Number') {
                        this.idCard.value = val.value ? val.value : ''
                        this.setState({ idCardValueFromOCR: val.value ? val.value : '' })
                    } else if (val.name === 'Date Of Birth') {
                        let output
                        if (val.validationChecks) {
                            let x = val.value.split(' ')
                            let yearTemp = x[x.length - 1]
                            let monthTemp = x[x.length - 2]
                            if (yearTemp.length >= 4) {
                                if (monthTemp.length >= 2) {
                                    this.setState({
                                        statusBirthDay: 1,
                                        birthDayFormat: "-/##/####"
                                    })
                                    let y = parseInt(yearTemp) + 543
                                    output = monthTemp + y.toString()
                                } else {
                                    this.setState({
                                        statusBirthDay: 2,
                                        birthDayFormat: "-/-/####"
                                    })
                                    let y = parseInt(yearTemp) + 543
                                    output = y.toString()
                                }
                            } else {
                                this.setState({
                                    statusBirthDay: 0,
                                    birthDayFormat: "##/##/####"
                                })
                                output = ""
                            }
                            let fields = this.state.field
                            fields["birthday"] = output
                            this.setState({
                                field: fields,
                                dateOfBirthValue: output
                            })
                            this.handleValidate()
                        } else {
                            this.setState({
                                statusBirthDay: 0,
                                birthDayFormat: "##/##/####"
                            })
                            // this.selectFormBirthDay(0)
                            let date = new Date(val.value).toLocaleDateString('th-TH',
                                {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'
                                })
                            try {
                                let splitDate = date.split('/')
                                let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
                                let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
                                output = day + mounth + splitDate[splitDate.length - 1]
                            } catch (err) {
                                output = ""
                            }
                        }
                        this.setState({ dateOfBirthValue: output })
                        // console.log(output)
                    } else if (val.name === 'Identity Card Number') {
                        this.idCardNum.value = val.value
                    } else if (val.name === 'Sex') {
                        this.selectGender(val.value)
                    }
                });
                clearTimeout(this.timeInterval)
                if (this.fullnameEn) {
                    this.selectGender(this.fullnameEn[1])
                }
                // this.selectGender(this.fullnameEn[1])
                this.setState({
                    documentOCRDataLoaded: true,
                    documentOCRData: response.data,
                    error: ""
                });
            })
            .catch((err) => {
                logEvent("id_card_ocr_conirm_[get-data]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[get-data]"),
                    response: err
                })
                // console.log(err)
                if (this.state.countGetApi < 4) {
                    this.timeInterval = setTimeout(() => {
                        this.getDocumentOCRData()
                        this.setState({ countGetApi: this.state.countGetApi + 1 })
                    }, process.env.REACT_APP_SET_TIME_INTERVAL || 5000)
                } else {
                    clearTimeout(this.timeInterval)
                    if (this.state.statusReprocess) {
                        if (err.response && err.response.data && err.response.data.message) {
                            this.setState({
                                documentOCRDataLoaded: true,
                                modalServerError: true,
                                error: err.response.data.message,
                            });
                        } else {
                            this.setState({
                                error: err.toString(),
                                documentOCRDataLoaded: true,
                                modalServerError: true
                            });
                        }
                    } else {
                        this.setState({
                            modalReprocess: true,
                            statusReprocess: true,
                            documentOCRDataLoaded: true
                        })
                        this.countDownReprocess()
                    }
                }
            });
    }
    //============================================[DOPA]=======================================================
    handleDopaSuccess = () => {
        setTimeout(() => {
            // this.props.go(FACE_CAPTURE_INSTRUCTIONS)
            this.props.nextStep();
        }, 200)
    }
    dopaResult = async () => {
        startEventTimer("id_card_ocr_conirm_[dopa-result]")
        logEvent("id_card_ocr_conirm_[dopa-result]", {
            start_time: window.performance.now(),
            response: null
        })
        const timeout = setTimeout(() => {
            this.setState({
                countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                dopaStatus: false,
                dopaServer: true,
                checkCount: true,
                textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
            })
        }, 20000);
        await this.props.applicationCheckDopaResult()
            .then((response) => {
                clearTimeout(timeout);
                logEvent("id_card_ocr_conirm_[dopa-result]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[dopa-result]"),
                    response: response
                })
                this.setState({
                    documentOCRDataLoaded: true,
                    disableIdField: true
                });
                // console.log(response)
                if (response.data.items.length > 0) {
                    let res;
                    for (let x in response.data.items) {
                        if (response.data.items[x].edcValues) {
                            res = response.data.items[x]
                        }
                    }
                    if (res.result === "COMPLETED") {
                        if (res.edcValues['resultCode'] === "0") {
                            this.setState({
                                dopaStatus: true,
                                dopaServer: false,
                                dopaResultStatus: true,
                                textStatusTitle: this.props.t("PageCheckDOPA.dopa_approve")
                            })
                            this.props.setResponseDopa(response.data)
                            this.handleDopaSuccess()
                        } else if (res.edcValues['resultCode'] === "6") {
                            if (this.autoRecheckDopa(this.birthDay)) {
                                if (this.state.defaultCountRetry <= this.props.dopacheckRetries) {
                                    this.setState({
                                        checkCount: false,
                                        dopaAutoRetryStatus: true,
                                        dopaAutoRetryErrorMessage: true,
                                        textStatusTitle: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_server_title")),
                                        textStatusText: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_server_text")),
                                    })
                                    this.dopaContinueRecheck();
                                }
                                this.setState({
                                    countRetry: this.showMessage(this.state.defaultCountRetry - this.props.dopacheckRetries),
                                    dopaStatus: false,
                                    dopaServer: true,
                                    checkCount: this.showMessage(true),
                                    dopaAutoRetryStatus: true,
                                    textStatusTitle: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_server_title")),
                                    textStatusText: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_server_text")),
                                })
                                this.dopaContinueRecheck();
                            } else {
                                this.setState({
                                    countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                                    dopaStatus: false,
                                    dopaServer: true,
                                    checkCount: true,
                                    textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                                    textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
                                })
                                this.pageScrollDown();
                            }
                        } else {
                            if (this.props.dopacheckRetries < this.state.defaultCountRetry) {
                                let retry = this.state.defaultCountRetry - this.props.dopacheckRetries
                                if (this.autoRecheckDopa(this.birthDay)) {
                                    this.setState({
                                        countRetry: this.showMessage(retry),
                                        dopaStatus: false,
                                        dopaServer: false,
                                        checkCount: this.showMessage(true),
                                        dopaAutoRetryStatus: true,
                                        textStatusTitle: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_title")),
                                        textStatusText: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_text")),
                                    })
                                    this.dopaContinueRecheck();
                                } else {
                                    this.setState({
                                        countRetry: retry,
                                        dopaStatus: false,
                                        dopaServer: false,
                                        checkCount: true,
                                        textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_title"),
                                        textStatusText: this.props.t("PageCheckDOPA.dopa_fail_text")
                                    })
                                	this.pageScrollDown();
                                }
                            } else {
                                if (this.autoRecheckDopa(this.birthDay)) {
                                    this.setState({
                                        dopaStatus: false,
                                        dopaServer: false,
                                        checkCount: false,
                                        dopaAutoRetryStatus: true,
                                        dopaAutoRetryErrorMessage: true,
                                        textStatusTitle: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_title")),
                                        textStatusText: this.showMessage(this.props.t("PageCheckDOPA.dopa_fail_text")),
                                    })
                                    this.dopaContinueRecheck();
                                } else {
                                    this.setState({
                                        dopaStatus: false,
                                        checkCount: false,
                                        dopaServer: false,
                                        onModal: true,
                                        textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_all")
                                	})
                                }
                            }
                        }
                    } else {
                        this.setState({
                            countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                            dopaStatus: false,
                            dopaServer: true,
                            checkCount: true,
                            textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                            textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
                        })
                        this.pageScrollDown();
                    }
                } else {
                    // if (this.props.dopacheckRetries < 2) {
                    if (this.props.dopacheckRetries < this.state.defaultCountRetry) {
                        // let retry = 2 - this.props.dopacheckRetries
                        this.setState({
                            countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                            dopaStatus: false,
                            dopaServer: true,
                            checkCount: true,
                            textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                            textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
                        })
                    } else {
                        this.setState({
                            dopaStatus: false,
                            checkCount: false,
                            dopaServer: false,
                            onModal: true,
                            textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_all")
                        })
                    }
                    this.pageScrollDown();
                }
            })
            .catch((err) => {
                logEvent("id_card_ocr_conirm_[dopa-result]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[dopa-result]"),
                    response: err
                })
                this.setState({
                    btnSubmitsts: false,
                    countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                    dopaStatus: false,
                    dopaServer: true,
                    checkCount: true,
                    documentOCRDataLoaded: true,
                    textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                    textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text"),
                    error: err.toString()
                })
                this.pageScrollDown();
                // handle no 'edcValues' 
                if (this.state.riskStatus && this.state.countRetry === 0) {this.setState({ onModal: true })}
            })
    }

    dopaCheckSubmit = () => {
        startEventTimer("id_card_ocr_conirm_[dopa-submit]")
        logEvent("id_card_ocr_conirm_[dopa-submit]", {
            start_time: window.performance.now(),
            response: null
        })
        const timeout = setTimeout(() => {
            this.setState({
                countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                dopaStatus: false,
                dopaServer: true,
                checkCount: true,
                textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
            })
            this.pageScrollDown();
        }, 20000);
        this.props.applicationSubmitForDopa()
            .then((response) => {
                logEvent("id_card_ocr_conirm_[dopa-submit]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[dopa-submit]"),
                    response: response
                })
                // console.log(response)
                this.props.setResponseDopa(response.data)
                clearTimeout(timeout);
                this.dopaResult()
            })
            .catch((err) => {
                logEvent("id_card_ocr_conirm_[dopa-submit]", {
                    end_time: getTimePassed("id_card_ocr_conirm_[dopa-submit]"),
                    response: err
                })
                this.setState({
                    btnSubmitsts: false,
                    countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                    dopaStatus: false,
                    dopaServer: true,
                    checkCount: true,
                    documentOCRDataLoaded: true,
                    textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                    textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text"),
                    error: err.toString()
                })
                this.pageScrollDown();
            })
    }
    dopaCheckTwoElementSubmit = () => {
      startEventTimer("id_card_ocr_conirm_[dopa-two-element-submit]")
        logEvent("id_card_ocr_conirm_[dopa-two-element-submit]", {
            start_time: window.performance.now(),
            response: null
        })
      const timeout = setTimeout(() => {
          this.setState({
              countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
              dopaStatus: false,
              dopaServer: true,
              checkCount: true,
              textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
              textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text")
          })
          this.pageScrollDown();
      }, 20000);
      this.props.applicationSubmitTwoElementForDopa()
        .then((response) => {
            logEvent("id_card_ocr_conirm_[dopa-two-element-submit]", {
                end_time: getTimePassed("id_card_ocr_conirm_[dopa-two-element-submit]"),
                response: response
            })
            // console.log(response)
            this.props.setResponseDopa(response.data)
            clearTimeout(timeout);
            this.dopaResult()
        })
        .catch((err) => {
            logEvent("id_card_ocr_conirm_[dopa-two-element-submit]", {
                end_time: getTimePassed("id_card_ocr_conirm_[dopa-two-element-submit]"),
                response: err
            })
            this.setState({
                btnSubmitsts: false,
                countRetry: this.state.defaultCountRetry - this.props.dopacheckRetries,
                dopaStatus: false,
                dopaServer: true,
                checkCount: true,
                documentOCRDataLoaded: true,
                textStatusTitle: this.props.t("PageCheckDOPA.dopa_fail_server_title"),
                textStatusText: this.props.t("PageCheckDOPA.dopa_fail_server_text"),
                error: err.toString()
            })
            this.pageScrollDown();
        })
    }
    goVerifyPage = () => {
        this.props.dopaRetry()
        this.props.resetDopaCheck()
        setTimeout(() => {
            this.props.go(VERIFY_IDENTITY)
        }, 200)
    }
    goOtherDocPage = () => {
      this.props.go(DOCUMENT_CAPTURE_OTHER_SELECTOR)
    }
    cancelDopa = () => {
        this.props.dopaRetry()
        this.props.resetDopaCheck()
        setTimeout(() => {
            // this.props.go(VERIFY_IDENTITY)
            if (!this.state.riskStatus) {this.props.go(VERIFY_IDENTITY)}
            else {this.props.go(DOCUMENT_CAPTURE_OTHER_SELECTOR)}
        }, 200)
    }
    goRecheckOCR = () => {
        // if (this.props.dopacheckRetries < 2 && !this.state.dopaServer) {
        if (
          (this.state.riskStatus &&
            this.props.dopacheckRetries < this.state.defaultCountRetry) ||
          (this.props.dopacheckRetries < this.state.defaultCountRetry &&
            !this.state.dopaServer)
        ) {
            this.setState({
                btnSubmitsts: true,
                checkCount: false,
                disableField: false,
                dopaServer: false,
                dopaAutoRetryStatus: false,
                textStatusTitle: '',
                textStatusText: ''
            })
            this.props.countDopaRetries();
        } else {
            this.setState({
                btnSubmitsts: true,
                checkCount: false,
                disableField: false,
                dopaServer: false,
                textStatusTitle: '',
                textStatusText: ''
            })
        }
    };
    goReTakePthoto = () => {
      this.setState({ onModalIncorrectIDCard: false})
      this.props.newDocumentSelected()
      this.props.go(DOCUMENT_CAPTURE_PORTRAIT)
    }
    //===================================================================================================================
    logCompletedStep() {
        const stepName = "document_ocr_confirm";
        const timePassed = getTimePassed(stepName);
        logEvent(step_completed, {
            stepNameCompleted: stepName,
            timePassed,
        });
    }
    hasRequiredFieldParams = () => {
      return (
        Object.values(this.props.requiredField).filter(v => 
          !['undefined', 'null', 'blank'].includes(v.toLowerCase())
        ).length > 0
      )
    }
    disableRequiredField = () => {
      const { idCard, firstName, lastName, dateOfBirth, gender } = this.props.requiredField
      this.idCard.value = idCard
      this.firstName.value = firstName
      this.lastName.value = lastName
      this.selectGender(gender)
      let output;
      let splitDate = dateOfBirth.split('/')
      let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
      let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
      output = day + mounth + splitDate[splitDate.length - 1]
      let fields = {
        ...this.state.field,
        pid: idCard,
        firstname: firstName,
        lastname: lastName,
        birthday: dateOfBirth
      }
      this.setState({
        field: fields,
        documentOCRDataLoaded: true,
        statusBirthDay: 0,
        birthDayFormat: "##/##/####",
        dateOfBirthValue: output
      })
      this.handleValidate()
    }
    submit = () => {
        this.props.setDataDOPACheck({
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            middleName: this.middleName.value,
            laserId: this.idCardNum.value,
            pid: this.idCard.value,
            birthDay: this.birthDay.value,
            gender: this.state.gender
        })
        // this.logCompletedStep();
        // this.props.go(CHECK_DOPA)
    };
    confirmData = (e) => {
        e.preventDefault()
        let errors = {};
        // let errorTemp = this.state.errors
        if (!this.firstName.value) {
            errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.first_name_not_null')
            this.setState({ firstNameValid: false })
        } else {
            if (typeof this.state.errors["firstname"] !== "undefined") {
                if (this.state.errors["firstname"]) {
                    errors["firstname"] = this.state.errors["firstname"]
                    this.setState({ firstNameValid: false })
                } else {
                    errors["firstname"] = ""
                    this.setState({ firstNameValid: true })
                }
            } else {
                errors["firstname"] = ""
                this.setState({ firstNameValid: true })
            }
        }
        if (!this.lastName.value) {
            errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.last_name_not_null')
            this.setState({ lastNameValid: false })
        } else {
            if (typeof this.state.errors["lastname"] !== "undefined") {
                if (this.state.errors["lastname"]) {
                    errors["lastname"] = this.state.errors["lastname"]
                    this.setState({ lastNameValid: false })
                } else {
                    errors["lastname"] = ""
                    this.setState({ lastNameValid: true })
                }
            } else {
                errors["lastname"] = ""
                this.setState({ lastNameValid: true })
            }
        }
        if (!this.idCardNum.value) {
            errors["laserid"] = this.props.t('PageDocumentConfirmOCRData.laser_id_not_null')
            this.setState({ laserValid: false })
        } else {
            if (typeof this.state.errors["laserid"] !== "undefined") {
                if (this.state.errors["laserid"]) {
                    errors["laserid"] = this.state.errors["laserid"]
                    this.setState({ laserValid: false })
                } else {
                    errors["laserid"] = ""
                    this.setState({ laserValid: true })
                }
            } else {
                errors["laserid"] = ""
                this.setState({ laserValid: true })
            }
        }
        if (!this.idCard.value) {
            errors["pid"] = this.props.t('PageDocumentConfirmOCRData.pid_not_null')
            this.setState({ pidValid: false })
        } else {
            if (typeof this.state.errors["pid"] !== "undefined") {
                if (this.state.errors["pid"]) {
                    errors["pid"] = this.state.errors["pid"]
                    this.setState({ pidValid: false })
                } else {
                    errors["pid"] = ""
                    this.setState({ pidValid: true })
                }
            } else {
                errors["pid"] = ""
                this.setState({ pidValid: true })
            }
        }
        if (!this.state.gender) {
            errors["gender"] = this.props.t('PageDocumentConfirmOCRData.gender_not_null')
            this.setState({ genderValid: false })
        } else {
            errors["gender"] = ""
            this.setState({ genderValid: true })
        }

        if (!this.birthDay.value) {
            errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_not_null')
            this.setState({ birtDayValid: false })
        } else {
            if (typeof this.state.errors["birthday"] !== "undefined") {
                if (this.state.errors["birthday"]) {
                    errors["birthday"] = this.state.errors["birthday"]
                    this.setState({ birtDayValid: false })
                } else {
                    errors["birthday"] = ""
                    this.setState({ birtDayValid: true })
                }
            } else {
                errors["birthday"] = ""
                this.setState({ birtDayValid: true })
            }
        }
        this.setState({ errors: errors });

        setTimeout(() => {
            // console.log(this.state.laserValid, this.state.firstNameValid, this.state.lastNameValid, this.state.birtDayValid, this.state.pidValid, this.state.genderValid, this.state.genderValid, this.state.birtDayValid)
            if (this.state.laserValid &&
                this.state.firstNameValid &&
                this.state.lastNameValid &&
                this.state.birtDayValid &&
                this.state.pidValid &&
                this.state.genderValid &&
                this.state.birtDayValid
            ) {
                // console.log("here")
                // this.documentPut()
                if (this.state.disableRequiredField) {
                  this.setState({ onModalReviewInfomation: true })
                } else {
                  this.documentPut()
                }
            }
        }, 200)

        // this.setState({ onModal: true })
    }
    retryCapturing = () => {
        this.props.go(DOCUMENT_CAPTURE_INSTRUCTIONS);
        this.props.retry();

        this.props.base64CroppedSecondPage
            ? this.props.resetingDocumentQualityRetries("back")
            : this.props.resetingDocumentQualityRetries("front");
    };
    handleValidate = () => {
        let fields = this.state.field;
        let errors = {};

        if (typeof fields["pid"] !== "undefined") {
            if (fields["pid"]) {
                if (!fields["pid"].match(/^\d+$/g)) {
                    errors["pid"] = this.props.t('PageDocumentConfirmOCRData.pid_number_only');
                    this.setState({ pidValid: false })
                } else {
                    if (fields["pid"].match(/^\d{14,}$/g)) {
                        errors["pid"] = this.props.t('PageDocumentConfirmOCRData.pid_more_13_digit');
                        this.setState({ pidValid: false })
                    } else if (fields["pid"].match(/^\d{1,12}$/g)) {
                        errors["pid"] = this.props.t('PageDocumentConfirmOCRData.pid_less_13_digit');
                        this.setState({ pidValid: false })
                    } else {
                        errors["pid"] = "";
                        this.setState({ pidValid: true })
                    }
                }
            } else {
                errors["pid"] = this.props.t('PageDocumentConfirmOCRData.pid_not_null');
                this.setState({ pidValid: false })
            }
        }

        if (typeof fields["laserid"] !== "undefined") {
            if (fields["laserid"]) {
                if (!fields["laserid"].match(/^[A-Z]{2}\d{10}$/g)) {
                    if (fields["laserid"].match(/^[a-z]{1,2}|[a-z]{1,2}\d{10}$/g)) {
                        errors["laserid"] = this.props.t('PageDocumentConfirmOCRData.laser_id_uppercase_only');
                    } else if (fields["laserid"].match(/^[$-/:.-?{-~!"^_`\\]$/g)) {
                        errors["laserid"] = this.props.t('PageDocumentConfirmOCRData.laser_id_ch_and_num_only');
                    } else if (fields["laserid"].match(/^[A-Z]{2}\d{10,}$/g)) {
                        // match(/^[A-Z]{2}\d{1,9}|^[A-Z]{2}\d{10,}$/g)
                        errors["laserid"] = this.props.t('PageDocumentConfirmOCRData.laser_id_must_10_digit');
                    } else if (fields["laserid"].match(/^[A-Z]{3,}$/g)) {
                        errors["laserid"] = this.props.t('PageDocumentConfirmOCRData.laser_id_ch_2_only');
                    } else {
                        errors["laserid"] = this.props.t('PageDocumentConfirmOCRData.laser_id_ch_2_only');
                    }
                    this.setState({ laserValid: false })
                } else {
                    errors["laserid"] = "";
                    this.setState({ laserValid: true })
                }
            } else {
                errors["laserid"] = this.props.t('PageDocumentConfirmOCRData.laser_id_not_null');
                this.setState({ laserValid: false })
            }
        }

        if (typeof fields["firstname"] !== "undefined") {
            // [\u0E00-\u0E7Fa-zA-Z']
            if (fields["firstname"]) {
                if (!fields["firstname"].match(/^[ๅภถุึคูตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ $-/:.-?{-~!"^_`\\]+$/g)) {
                    errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.firstname_th_only');
                    this.setState({ firstNameValid: false })
                } else {
                    errors["firstname"] = "";
                    this.setState({ firstNameValid: true })
                }
            } else {
                errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.first_name_not_null');
                this.setState({ firstNameValid: false })
            }
        }
        // ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ
        if (typeof fields["lastname"] !== "undefined") {
            if (fields["lastname"]) {
                if (!fields["lastname"].match(/^[ๅภถุึคูตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ $-/:.-?{-~!"^_`\\]+$/g)) {
                    errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.lastname_th_only');
                    this.setState({ lastNameValid: false })
                } else {
                    errors["lastname"] = "";
                    this.setState({ lastNameValid: true })
                }
            } else {
                errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.last_name_not_null');
                this.setState({ lastNameValid: false })
            }
        }
        if (typeof fields["birthday"] !== "undefined") {
            if (fields["birthday"]) {
                if (!fields["birthday"].match(/^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[0-2])[-/.]\d{4}$/g)) {
                    errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                    this.setState({ birtDayValid: false })
                } else {
                    let getMinDate = fields["birthday"].split('/');
                    let getMaxDate = this.state.setCurrentDate.split('/');
                    if (getMinDate[getMinDate.length - 1].length >= 4) {
                        if (parseInt(getMinDate[getMinDate.length - 1]) >= 2420 &&  // Min year
                            parseInt(getMinDate[getMinDate.length - 1]) <= parseInt(getMaxDate[getMaxDate.length - 1]) // Max year
                        ) {
                            if (parseInt(getMinDate[getMinDate.length - 1]) === parseInt(getMaxDate[getMaxDate.length - 1]) &&
                                ((parseInt(getMinDate[0]) > parseInt(getMaxDate[0]) && parseInt(getMinDate[1]) === parseInt(getMaxDate[1])) || // Max day
                                    parseInt(getMinDate[1]) > parseInt(getMaxDate[1])) // Max month
                            ) {
                                errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                                this.setState({ birtDayValid: false })
                            } else {
                                errors["birthday"] = "";
                                this.setState({ birtDayValid: true })
                            }
                        } else {
                            errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                            this.setState({ birtDayValid: false })
                        }
                    }
                }
            } else {
                errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_not_null');
                this.setState({ birtDayValid: false })
            }
        }
        this.setState({ errors: errors });
    }
    handleChange = (e, field) => {
        e.preventDefault();
        // console.log(e, field)
        let fields = this.state.field
        fields[field] = e.target.value
        // console.log(fields)
        if (typeof fields["laserid"] !== "undefined") {
            fields["laserid"] = fields["laserid"].toUpperCase();
        }
        this.setState({ field: fields });
        this.handleValidate();
    }
    selectTitleName = (titleEn, titleTh) => {
        this.setState({ titleNameTh: titleTh, titleNameEn: titleEn })
        this.selectGender(titleEn)
    }
    selectGender = (title) => {
        if (title === "Mr." || title === "M") {
            this.female.checked = false
            this.male.checked = true
            this.setState({ gender: "Male" })
        } else if (title === "Mrs." || title === "Miss" || title === "F") {
            this.female.checked = true
            this.male.checked = false
            this.setState({ gender: "Female" })
        } else if (this.male.checked === true) {
            this.setState({ gender: "Male" })
        } else if (this.female.checked === true) {
            this.setState({ gender: "Female" })
        }
    }
    selectFormBirthDay = () => {
        if (this.state.statusBirthDay === 1 || this.state.statusBirthDay === 2) {
            this.setState({
                dateOfBirthValue: "",
                statusBirthDay: 0,
                birthDayFormat: "##/##/####"
            })
        }
    }
    render() {
        return (
            <PageContent toggleLoading={!this.state.documentOCRDataLoaded} progressBar={true} isSpinner={true}>
                <h1>{this.props.t("PageDocumentConfirmOCRData.title")}</h1>
                <h1>{this.props.t("PageDocumentConfirmOCRData.title_x")}</h1>
                <form onSubmit={(e) => this.confirmData(e)}>
                    <div className="card-body" style={{ marginBottom: '30%' }}>
                        <div className="container-fluid mt-1">
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.id_card")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.idCard = input}
                                            placeholder=""
                                            disabled={!this.state.disableRequiredField ? this.state.disableField : this.state.disableRequiredField}
                                            onChange={(e) => this.handleChange(e, "pid")}
                                        // required
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["pid"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center mt-2">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.firstname")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.firstName = input}
                                            placeholder=""
                                            disabled={!this.state.disableRequiredField ? this.state.disableField : this.state.disableRequiredField}
                                            onChange={(e) => this.handleChange(e, "firstname")}
                                        // required
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.middlename")}</label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.middleName = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "middlename")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["middlename"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.lastname")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.lastName = input}
                                            placeholder=""
                                            disabled={!this.state.disableRequiredField ? this.state.disableField : this.state.disableRequiredField}
                                            onChange={(e) => this.handleChange(e, "lastname")}
                                        // onClick={(e) => this.handleChange(e, "lastname")}
                                        // required
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.date_of_birth")}<b style={{ color: "red" }}>*</b></label>
                                        <label style={{ color: "#22A8DA", fontSize: '0.8em', marginTop: '0px', cursor: 'pointer', textAlign: 'right' }} onClick={() => this.setState({ onModalBirthDayExample: true })}><u><i>{this.props.t("PageDocumentConfirmOCRData.id_card.laser_id_ex")}</i></u></label>
                                        <NumberFormat
                                            type="text"
                                            className="form-control"
                                            getInputRef={(input) => (this.birthDay = input)}
                                            value={this.state.dateOfBirthValue}
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                this.setState({ dateOfBirthValue: value });
                                            }}
                                            format={this.state.birthDayFormat}
                                            mask="_"
                                            placeholder={this.state.placeHolder}
                                            onFocus={() => this.selectFormBirthDay()}
                                            onChange={(e) => this.handleChange(e, "birthday")}
                                            disabled={!this.state.disableRequiredField ? this.state.disableField : this.state.disableRequiredField}
                                        />
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["birthday"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.gender")}<b style={{ color: "red" }}>*</b></label>
                                    <div className="row">
                                        <label className="radio-button">
                                            <input
                                                type='radio'
                                                ref={(input) => this.male = input}
                                                onClick={() => this.selectGender()}
                                                name='radio'
                                                disabled={!this.state.disableRequiredField ? this.state.disableField : this.state.disableRequiredField}
                                            // required
                                            />
                                            <span className="indicator"></span><span className="p3" style={{ fontSize: '1.7em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.male")}</span>
                                        </label>
                                        <label className="radio-button ml-5">
                                            <input
                                                type='radio'
                                                ref={(input) => this.female = input}
                                                onClick={() => this.selectGender()}
                                                name='radio'
                                                disabled={!this.state.disableRequiredField ? this.state.disableField : this.state.disableRequiredField}
                                            // required
                                            />
                                            <span className="indicator"></span><span className="p3" style={{ fontSize: '1.7em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.female")}</span>
                                        </label>
                                        <span className="ml-3" style={{ color: "red" }}>{this.state.errors["gender"]}</span>
                                    </div>

                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em', marginTop: '0px' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.laser_id")}<b style={{ color: "red" }}>*</b></label>
                                        <label style={{ color: "#22A8DA", fontSize: '0.8em', marginTop: '0px', cursor: 'pointer', textAlign: 'right' }} onClick={() => this.setState({ onModalExample: true })}><u><i>{this.props.t("PageDocumentConfirmOCRData.id_card.laser_id_ex")}</i></u></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.idCardNum = input}
                                            style={{ marginTop: '0px', textTransform: "uppercase" }}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "laserid")}
                                        // required
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["laserid"]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.state.dopaStatus && (this.state.textStatusTitle && this.state.textStatusText) ? (<div className="container">
                            <div className="card-body mt-3 mb-3" style={{ backgroundColor: '#ffccb0', borderRadius: '5px' }}>
                                <div className="row no-gutters">
                                    <div className="col-md-2">
                                        <img
                                            style={{ width: '50px', height: 'inherit' }}
                                            src={imgerror}
                                            alt="error"
                                        />
                                    </div>
                                    <div className="col-md-10">
                                        <h5><b>{this.state.textStatusTitle}</b></h5>
                                        <p style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.state.textStatusText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>) : null}
                        {/* <p className="error">{this.state.error}</p> */}
                    </div>

                    <footer className="footer d-flex align-items-center flex-column bd-highlight">
                        <a style={{ fontFamily: 'AIA Regular', color: "black", marginBottom: "0px", marginTop: "0px" }}>{this.props.t("PageDocumentConfirmOCRData.confirm_data")}</a>
                        {this.state.btnSubmitsts ? (<button
                            disabled={this.state.buttonsDisabled || !this.state.documentOCRDataLoaded}
                            type="submit"
                            value="submit"
                            className="btn"
                            style={{ backgroundColor: "#D31145", fontSize: '1em', marginTop: '0px' }}
                        >
                            {this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}
                        </button>) : null}
                        {this.state.dopaAutoRetryStatus && this.state.dopaAutoRetryErrorMessage ? (<button
                            value="button"
                            className="btn"
                            style={{ backgroundColor: "#D31145", fontSize: '1em', marginTop: '0px' }}
                            onClick={() => this.setState({ onModal: true, dopaAutoRetryErrorMessage: false })}
                        >
                            {this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}
                        </button>) : null}
                        {!this.state.dopaStatus && this.state.checkCount ? (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-lg btn-link bi-chevron-compact-left mb-2"
                                    onClick={() => this.cancelDopa()}
                                    style={{ color: "#333D47", fontSize: '1em', marginTop: '0px' }}>
                                    {this.props.t("PageCheckDOPA.btn_dopa_cancel")}
                                </button>
                                {/* <button
                                    type="button"
                                    className="btn btn-lg"
                                    onClick={() => this.goRecheckOCR()}
                                    style={{ backgroundColor: "#D31145", color: "white", fontSize: '1em', marginTop: '0px' }}>
                                    {this.state.dopaServer ? this.props.t("PageCheckDOPA.btn_dopa_retry_2") : this.props.t("PageCheckDOPA.btn_dopa_retry", { count: this.state.countRetry })}
                                </button> */}

                                {!this.state.riskStatus ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg"
                                    onClick={() => this.goRecheckOCR()}
                                    style={{ backgroundColor: "#D31145", color: "white", fontSize: '1em', marginTop: '0px' }}>
                                    {this.state.dopaServer ? this.props.t("PageCheckDOPA.btn_dopa_retry_2") : this.props.t("PageCheckDOPA.btn_dopa_retry", { count: this.state.countRetry })}
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-lg"
                                    onClick={() => this.goRecheckOCR()}
                                    style={{ backgroundColor: "#D31145", color: "white", fontSize: '1em', marginTop: '0px' }}>
                                    {this.props.t("PageCheckDOPA.btn_dopa_retry", { count: this.state.countRetry })}
                                  </button>
                                )
                                
                                }
                            </>) : null}
                    </footer>
                </form>

                <Modal
                    show={this.state.onModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '25%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            {/* <p className="mt-2 w-full" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageCheckDOPA.dopa_fail_all_1')}</p> */}
                            <p className="mt-2 w-full" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageCheckDOPA.dopa_fail_all_1', { count: this.state.defaultCountRetry + 1 })}</p>
                            {/* <a className="mt-3 mb-4 w-full" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageCheckDOPA.dopa_fail_all_2')}</a> */}
                        </div>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '100%', height: 'inherit' }}
                                src={this.props.setupLanguage === "th" ? otherSelectTH : otherSelectEN}
                                alt="warning"
                            />
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.cancelDopa()}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.modalReprocess}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentConfirmOCRData.id_card.reprocess_get_ocr_data')}</a>
                            <a className="mt-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.countReprocess}</a>
                        </div>
                        <div className="row row-justify-center">
                            <button
                                type="button"
                                className="btn btn-lg"
                                disabled={this.state.disableBtnReprocess}
                                style={{ backgroundColor: "#D31145", fontSize: '1em' }}
                                onClick={() => this.getDocumentOCRData()}
                            >{this.props.t("PageDocumentConfirmOCRData.btn_retry_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.modalServerError}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgerror}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentConfirmOCRData.id_card.server_error_get_ocr_data')}</a>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.cancelDopa()}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModalExample}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '80%', height: 'inherit' }}
                                src={idcardBack}
                                alt="idcard-back"
                            />
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ onModalExample: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModalBirthDayExample}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-start">
                            <h4>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_header")}</h4>
                            <p className="mt-3" style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_tile")}</p>
                            <p style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_ex1")}</p>
                            <p style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_ex2")}</p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ onModalBirthDayExample: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModalReviewInfomation}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '25%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <p className="mt-2 w-full" style={{ color: 'black', fontFamily: 'AIA Regular', marginBottom: 0 }}>{this.props.t('PageCheckDOPA.infomation_review')}</p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.goDocumentPut()}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModalIncorrectIDCard}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '25%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <p className="mt-2 w-full" style={{ color: 'black', fontFamily: 'AIA Regular', marginBottom: 0 }}>{this.props.t('PageCheckDOPA.infomation_incorrect_IDCard')}</p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.goReTakePthoto()}>{this.props.t("PageFaceCaptureConfirm.retake_photo")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                  show={this.state.modalErrType}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Body>
                    <div className="row row-justify-center">
                      <img
                        style={{ width: '30%', height: 'inherit' }}
                        src={imgwarning}
                        alt="warning"
                      />
                    </div>
                    <div className="row">
                      <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCaptureConfirm.error_type_document')}</a>
                    </div>
                    <div className="row row-justify-center">
                      <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={this.goOtherDocPage}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                    </div>
                  </Modal.Body>
                </Modal>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageShowOCRIdentityCard);
export default connect(
    (state) => {
        const {
            allRetriesFailed,
            base64CroppedFirstPage,
            base64CroppedSecondPage,
            blobFirstPage,
            blobSecondPage,
            documentType,
            message,
            isRejected,
            processingRetriesCounter,
            retries,
            countIncorrectIDCard,
            summaryScorePresent,
        } = state.documentCapture;
        return {
            ...state.setApplicationParams,
            ...state.changeMultiLanguage,
            ...state.configuration.extraConfig.featureFlags,
            ...state.documentCaptureConfiguration,
            ...state.documentCaptureSetData,
            ...state.dopaCheck,
            allRetriesFailed,
            base64CroppedFirstPage,
            base64CroppedSecondPage,
            blobFirstPage,
            blobSecondPage,
            documentType,
            message,
            isRejected,
            processingRetriesCounter,
            retries,
            countIncorrectIDCard,
            summaryScorePresent,
        };
    },
    {
        go,
        nextStep,
        documentsGet,
        documentUpdate,
        retry,
        newDocumentSelected,
        resetingDocumentQualityRetries,
        retryIncorrectIdCard,
        setDataDOPACheck,
        setTwoElementCheck,
        applicationSubmitForDopa,
        applicationSubmitTwoElementForDopa,
        countDopaRetries,
        resetDopaCheck,
        setResponseDopa,
        dopaRetry,
        applicationCheckDopaResult
    }
)(componentWithTranslation)