import { store } from "../../store";
import { SET_TEST_DATA, ERROR_MESSAGE, setVideoId } from "../livenessTest";
import { UPDATE_MESSAGE, instantiateFaceLiveness3D } from "../faceLiveness3D";
// import {
//   CACHE_LIVENESS_TEST,
//   writeToLocalCache,
// } from "../../localCacheAdapter";
import { axiosInstance } from "logic/api";

import { delayedPromise } from "logic/delayedPromise";
import { livenessSetupResponse, livenessResponse } from "mocks/applicationPost";
/** 
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/challenges?livenessPolicyName={livenessPolicyName}
 */
export const challengeCreate = () => (dispatch) => {
  const { application, configuration } = store.getState();
  let promise = null

  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(livenessSetupResponse)
  } else {
    promise = axiosInstance.post(
      `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/challenges?livenessPolicyName=${configuration.livenessPolicyName}`
    );
  }

  return promise.then((response) => {
    // console.log(response)
    dispatch({ type: SET_TEST_DATA, payload: response.data });
    instantiateFaceLiveness3D()(dispatch);

    // writeToLocalCache(response.data, CACHE_LIVENESS_TEST);
    return response;
  });
};

/**
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/videos/?{videoId | ""}
 */
export const challengeTemplateUpload = (base64Template) => (dispatch) => {
  dispatch({ type: UPDATE_MESSAGE, payload: "" });
  const { application, livenessTest, configuration } = store.getState();
  let promise = null;
  var action;
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(livenessResponse)
  } else {
    if (livenessTest.livenessTestData.items[0].type === "SVR3DFL" && configuration.extraConfig.featureFlags && configuration.extraConfig.featureFlags.new3dfl === "true" && configuration.extraConfig.featureFlags.selfie3dfl === "true") {
      const request = {
        type: "Face",
        format: "Liveness3D",
        context: {},
        data: {
          value: base64Template,
        },
      };
      promise = axiosInstance.post(
        `/IdentityXServices/rest/v1/users/${application.user.id}/samples`,
        request
      );
    } else {
      const subtype =
        livenessTest.livenessTestData.items[0].type === "SVR3DFL"
          ? "SVR3DFL_CHALLENGE"
          : "WEB_CLR";
      const request = {
        subtype: subtype,
        captured: new Date().toISOString(),
        videoFormat: livenessTest.livenessTestData.items[0].type,
        challenges: [
          {
            challenge: {
              id: livenessTest.livenessTestData.items[0].id,
              type: livenessTest.livenessTestData.items[0].type,
            },
            start: 0,
            completed: 99999,
          },
        ],
        sensitiveData: {
          format: livenessTest.livenessTestData.items[0].type,
          value: base64Template,
        },
      };

      if (window.dtrum) {
        action = window.dtrum.enterAction('9.Capture Liveness');
      }
      promise = axiosInstance.post(
        `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/videos/${livenessTest.videoId || ""}`,
        request
      );
    }
  }
  return promise.then((response) => {
    // console.log(response)
    if (window.dtrum) {
      window.dtrum.leaveAction(action);
    }
    if (response.data.processingStatus === "FAILED") {
      dispatch({
        type: ERROR_MESSAGE,
        payload: response.data.processingErrors.items[0].message,
      });
    }
    if (response.data.id) {
      dispatch(setVideoId(response.data.id));
    }
    return response;
  });
};
